/* eslint-disable no-debugger */
import { mapState } from 'vuex';

export const format = function (value, locale, currency, fractions, display = 'code') {
    if (typeof value !== "number") {
        return value;
    }

    // TODO: Fractions should by default be 2. But to speed up time to production we set the Swedish "default".
    // We need to fix fractions and currency (handle more then sv and SEK) at some time. 
    if (typeof fractions !== "number" || fractions === undefined) fractions = 0; // fractions = 2;

    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: fractions,
        maximumFractionDigits: fractions,
        currencyDisplay: display // code | narrowSymbol
    });

    let formatted = formatter.format(value);

    if (formatted.indexOf(currency) === 0) {
        formatted = `${formatted.substring(4, formatted.length)} ${currency}`;
    }

    return formatted;
}

export const getPrice = (entry, type, includeVat, country, currency) => {
    const prices = entry.prices[country].find(c => c.currency === currency);
    type = type || (prices.campaignPrice ? 'campaign' : 'sales');
    const price = prices[`${type}Price`];

    return includeVat
        ? price + getVat(prices, type)
        : price;
}

const getVat = (prices, type) => {
    let taxes = prices[`${type}Taxes`];
    return taxes.filter(st => st.type === 'VAT')
        .map(function (tax) {
            return tax.amount;
        })
        .reduce(function (acc, amount) {
            return acc + amount;
        }, 0);
}

export const prices = {
    computed: {
        ...mapState({
            pricing: state => state.pricing
        })
    },
    methods: {
        quantityPrice(
            products,
            times,
            locale = this.$i18n.locale,
            countryId = this.pricing.countryId,
            currencyId = this.pricing.currencyId,
            includeVat = this.pricing.includeVat) {

            const price = products.map(product =>
                getPrice(
                    product,
                    null,
                    includeVat,
                    countryId,
                    currencyId) * product.quantity)
                .reduce((accumulator, price) => accumulator + price, 0);

            return format(price * times, locale, currencyId, 2);
        },
        unitPrice(
            entry,
            locale = this.$i18n.locale,
            countryId = this.pricing.countryId,
            currencyId = this.pricing.currencyId,
            includeVat = this.pricing.includeVat,
            display = 'narrowSymbol') {
            const price = getPrice(entry, null, includeVat, countryId, currencyId);
            return format(price, locale, currencyId, 2, display);
        },
        unitDiscount(
            entry,
            locale = this.$i18n.locale,
            countryId = this.pricing.countryId,
            currencyId = this.pricing.currencyId,
            includeVat = this.pricing.includeVat) {
            const price = getPrice(entry, 'discount', includeVat, countryId, currencyId);
            return format(price * entry.quantity, locale, currencyId, 2);
        },
        comparisonPrice(
            entry,
            countryId = this.pricing.countryId,
            currencyId = this.pricing.currencyId,
            includeVat = this.pricing.includeVat,
            display = 'narrowSymbol') {
            const price = getPrice(entry, null, includeVat, countryId, currencyId);
            return format((price / entry.weightinGrams) * 1000, 'sv', currencyId, 2, display);
        },
        getInstancePrice(
            entries,
            locale = this.$i18n.locale,
            countryId = this.pricing.countryId,
            currencyId = this.pricing.currencyId,
            includeVat = this.pricing.includeVat) {
            const prices = entries.map(entry => getPrice(entry, null, includeVat, countryId, currencyId) * entry.quantity)
                .sort((a, b) => a - b);

            return format(prices.length > 0 ? prices[0] : 0, locale, currencyId, 2);
        },
        getInstanceDiscount(
            entries,
            quantity = 1,
            locale = this.$i18n.locale,
            countryId = this.pricing.countryId,
            currencyId = this.pricing.currencyId,
            includeVat = this.pricing.includeVat) {
            const prices = entries.map(entry => getPrice(entry, 'discount', includeVat, countryId, currencyId) * entry.quantity)
                .sort((a, b) => b - a);

            return format(prices.length > 0 ? prices[0] * quantity : 0, locale, currencyId, 2);
        },
        currencyFormat(
            value,
            locale = this.$i18n.locale,
            currencyId = this.pricing.currencyId) {
            return format(value, locale, currencyId, 2);
        }
    }
}
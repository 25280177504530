const translationStatus = {};

const status = Object.freeze({
    Loading: Symbol('loading'),
    Loaded: Symbol('loaded')
})

import {
    objects
} from '../commons';

export async function loadLanguageAsync(i18n, name) {
    return import(/* webpackChunkName: "component-translation-[request]" */ `@/locales/components/${name}.${i18n.locale}`)
        .then(messages => {
            const current = i18n.getLocaleMessage(i18n.locale).$pipeline?.[name];
            const value = current
                ? objects.methods.merge(messages.default, current)
                : messages.default;

            i18n.mergeLocaleMessage(i18n.locale, {
                $pipeline: {
                    [name]: value
                }
            });
        });
}

export const i18nLoader = {
    /* eslint-disable no-debugger */
    /* eslint-disable no-unused-vars */
    methods: {
        i18nAwait(key) {
            return !this.$te(key);
        },
        $tcc(key, args) {
            return this.$t(`$pipeline.${this.$options.name}.${key}`, args);
        },
        $tcg(key, args) {
            return this.$t(`$pipeline.app.general.${key}`, args)
        }
    },
    computed: {
        atc() {
            return this.i18nAwait(`$pipeline.${this.$options.name}`);
        },
    },
    async beforeCreate() {
        const name = `${this.$options.name}-${this.$i18n.locale}`;

        if (translationStatus[name] !== undefined) {
            return;
        }

        translationStatus[name] = status.Loading;
        await loadLanguageAsync(this.$i18n, this.$options.name)
            .then(() => translationStatus[name] = status.Loaded);
    }
}
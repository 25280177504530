/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');

const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'en',
    postTranslation: (ret, key) => {
        if (ret === key) {
            return key + ' [[MISSING]]';
        }

        return ret;
    },
    missing: (locale, key, vm) => {
        if (vm) {
            const translations = vm.$store?.state?.configuration?.custom?.translations;

            if (translations) {
                let value = translations.find(t => t.key === key)?.value;

                if (value) {
                    const vObj = {};

                    key.split('.').reduce((obj, prop, idx, array) => obj[prop] = idx < (array.length - 1) ? {} : value, vObj);
                    i18n.mergeLocaleMessage(i18n.locale, vObj);

                    return value;
                }
            }

            if (vm && locale !== vm.$i18n.fallbackLocale) {
                return vm.$t(key, vm.$i18n.fallbackLocale);
            }
        }

        return key
    },
});

export default i18n;
<template>
    <v-app>
        <v-app-bar app
                   prominent
                   hide-on-scroll
                   elevate-on-scroll
                   color="white"
                   v-model="bars.app">
            <template #img="{ props }">
                <v-img v-bind="props"
                       width="169px"
                       height="169px"
                       src="/images/zooingvar_parrot_symbol.svg"
                       position="-40px -40px"
                       transition="scroll-x-transition" />
            </template>
            <v-spacer />
            <div id="reco--badge-yearsInRowBadge"
                 class="mr-1 mt-1" />
            <v-sheet color="transparent"
                     class="mt-2">
                <v-badge v-bind:content="cart.quantity"
                         v-bind:value="cart.quantity"
                         v-bind:class="{ shake: shake }"
                         bordered
                         overlap
                         color="warning"
                         offset-x="16"
                         offset-y="16"
                         class="mr-4">
                    <v-btn v-bind:to="{ name: 'store-checkout', params: { culture: $i18n.locale } }"
                           class="mx-2"
                           elevation="0"
                           fab
                           small
                           dark
                           color="primary">
                        <v-icon v-html="icons.cart" />
                    </v-btn>
                </v-badge>
                <v-menu v-model="bars.bar"
                        v-bind:close-on-click="false"
                        open-on-click
                        bottom
                        right
                        offset-y
                        transition="slide-x-transition">
                    <template #activator="{ on, attrs }">
                        <v-btn color="primary"
                               elevation="0"
                               fab
                               small
                               v-bind="attrs"
                               v-on="on"
                               class="included">
                            <v-icon v-text="icons.dotsVertical" />
                        </v-btn>
                        <v-scroll-x-reverse-transition>
                            <v-sheet v-show="bars.bar"
                                     v-click-outside="{
                                        handler: onClickOutside,
                                        include: onClickOutsideIncluded
                                     }"
                                     class="transparent px-2"
                                     style="position: absolute; top:0; left: 0; width: 100%; z-index: 6;">
                                <v-card outlined
                                        class="mt-2">
                                    <v-card-actions>
                                        <v-spacer />
                                        <v-btn v-on:click="go"
                                               text
                                               rounded
                                               x-large>
                                            {{ $tcg('language.change.text', { language: $store.state.configuration?.culture === 'en' ? $tcg('language.en.sv') : $tcg('language.sv.en') }) }}
                                        </v-btn>
                                        <v-spacer />
                                        <v-btn v-on:click="logonClick"
                                               text
                                               rounded
                                               x-large>
                                            {{ $tcg('logon.text') }}
                                            <v-icon v-html="icons.account"
                                                    class="custom-svg ml-3"
                                                    right />
                                        </v-btn>
                                        <v-spacer />
                                    </v-card-actions>
                                </v-card>
                            </v-sheet>
                        </v-scroll-x-reverse-transition>
                    </template>
                </v-menu>
            </v-sheet>
            <template #extension>
                <v-slide-x-transition>
                    <v-img v-show="!bars.app"
                           width="82"
                           height="82"
                           position="-18px -18px"
                           transition="false"
                           src="/images/zooingvar_parrot_symbol.svg"
                           style="position: absolute; top: 0; left: 0;" />
                </v-slide-x-transition>
                <v-toolbar-items style="transition: margin 0.2s ease-in-out;"
                                 v-bind:class="{ 'ml-12': !bars.app }">
                    <v-skeleton-loader id="home-skeleton-loader"
                                       v-bind:loading="i18nAwait('$pipeline.v-app.app-bar.btns.home.text')"
                                       transition="fade-transition"
                                       v-slot
                                       class="v-toolbar__items v-btn v-btn--plain">
                        <v-btn v-bind:to="{ name: 'home', params: { culture: $i18n.locale } }"
                               exact-path
                               plain
                               x-large
                               elevation="0"
                               class="v-btn--hover v-btn--home">
                            {{ $t('$pipeline.v-app.app-bar.btns.home.text') }}
                            <v-icon v-html="icons.home"
                                    class="custom-svg ml-3"
                                    right />
                        </v-btn>
                    </v-skeleton-loader>
                </v-toolbar-items>
                <v-spacer v-if="!$store.state.initialized || !$vuetify.breakpoint.xs" />
                <v-toolbar-items class="flex-fill flex-sm-grow-0">
                    <v-menu v-model="menus[tab.id]"
                            v-for="tab in menuTabs"
                            v-bind:key="tab.id"
                            open-on-click
                            content-class="elevation-0 fill-height pa-0 top"
                            origin="top center"
                            min-width="100%">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-on="on"
                                   v-bind="attrs"
                                   v-bind:key="`tab-${tab.id}`"
                                   v-on:click.stop.prevent="menus[tab.id] = !menus[tab.id]"
                                   v-bind:class="`v-btn--${tab.elementId}`"
                                   v-bind:style="{ 'min-width': '174px' }"
                                   elevation="0"
                                   x-large
                                   plain
                                   class="flex-fill flex-sm-grow-0 v-btn--hover font-weight-bold">
                                {{ tab.name }}
                                <v-icon v-bind:style="{ 'background-image': 'url(/images/' + tab.elementId + '-black.svg)' }"
                                        v-bind:class="`v-icon--${tab.elementId}`"
                                        class="custom-svg ml-3"
                                        right />
                            </v-btn>
                        </template>
                        <navigation-menu v-bind:root-id="tab.id"
                                         v-bind:navigations="menuNavigations" />
                    </v-menu>
                </v-toolbar-items>
                <v-app-account-toolbar-item class="hidden-xs-only" />
                <v-algolia-dialog-search />
            </template>
        </v-app-bar>
        <v-fabs v-bind:app-bar-visible="bars.app"
                ref="fabs" />
        <v-main style="min-height: 110vh;">
            <v-overlay z-index="7"
                       v-bind:value="overlay" />
            <v-container>
                <v-alert border="bottom"
                         colored-border
                         type="info"
                         color="avian"
                         elevation="2"
                         class="mb-0 pb-6">
                    {{ $tcg('new-site') }}
                </v-alert>
            </v-container>
            <router-view />
        </v-main>

        <v-footer padless
                  min-height="160"
                  color="white">
            <v-row justify="center"
                   class="text-center"
                   no-gutters>
                <v-col cols="12"
                       v-for="menu in $store.state.configuration?.footer?.menuRows"
                       v-bind:key="menu">
                    <v-btn v-for="(page, idx) in $store.state.navigations.find(node => node.elementId === menu)?.contentPages"
                           v-bind:key="`de00-${idx}`"
                           v-bind:to="{ name: 'page', params: { culture: $i18n.locale, pathMatch: page.path } }"
                           exact
                           text
                           class="my-2 mx-1">
                        {{ page.name }}
                    </v-btn>
                </v-col>
                <v-col cols="12"
                       class="py-4 avian white--text">
                    {{ new Date().getFullYear() }} &mdash; ZOOINGVAR AB
                </v-col>
            </v-row>
        </v-footer>
    </v-app>
</template>

<script>
    /* eslint-disable no-debugger */
    // https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
    import { mapState } from 'vuex';
    import '@stripe/stripe-js';

    import {
        shake,
        i18nLoader
    } from './mixins/commons';

    import {
        mdiAccountCircleOutline,
        mdiCartVariant,
        mdiCheck,
        mdiDotsVertical,
        mdiHomeOutline,
        mdiMagnify,
        mdiMenu
    } from '@mdi/js';

    import VAppAccountToolbarItem from './components/AppAccountToolbarItem';
    import * as actions from './store/action-types';
    import * as mutations from './store/mutation-types';

    let timerId = undefined;

    export default {
        name: 'app',
        mixins: [
            shake,
            i18nLoader
        ],
        components: {
            NavigationMenu: () => import(/* webpackChunkName: 'navigation-menu' */ './components/NavigationMenu'),
            VAlgoliaDialogSearch: () => import(/* webpackChunkName: 'algolia-dialog-search' */ './components/AlgoliaDialogSearch'),
            VAppAccountToolbarItem, //: () => import(/* webpackChunkName: 'app-account-toolbar-item' */ './components/AppAccountToolbarItem'),
            VFabs: () => import(/* webpackChunkName: 'fabs' */ './components/Fabs'),
        },
        data: () => ({
            bars: {
                app: true,
                bar: false
            },
            icons: {
                account: mdiAccountCircleOutline,
                cart: mdiCartVariant,
                check: mdiCheck,
                dotsVertical: mdiDotsVertical,
                home: mdiHomeOutline,
                magnify: mdiMagnify,
                menu: mdiMenu,
            },
            rounded: false,
            menus: {}
        }),
        computed: {
            ...mapState({
                overlay: state => state.overlay,
                cart: state => state.cart,
                fabs: state => state.fabs
            }),
            menuTabs() {
                return this.$store.state.navigations.filter(node => {
                    return !node.isHidden && node.parentId === null;
                });
            },
            menuNavigations() {
                return this.$store.state.navigations.filter(node => {
                    return !node.isHidden;
                });
            }
        },
        watch: {
            '$i18n.locale'() {
                //this.loadMenu();
            },
            menuNavigations(to) {
                this.menus = Object.assign({}, ...to.filter(mi => mi.parentId == null)
                    .map(mi => ({ [mi.id]: false })));
            },
            menus: {
                handler: function (current) {
                    this.input(Object.keys(current).some(key => current[key]));
                },
                deep: true
            }
        },
        methods: {
            async input(value) {
                this.$store.dispatch(actions.SET_LOCK, {
                    lock: value,
                    overlay: value
                });
            },
            async notification(message) {
                this.$refs.fabs.notification(message);
            },
            async logonClick() {
                this.onClickOutside();
                this.$store.dispatch(actions.UI, {
                    element: 'logon',
                    value: true
                });
            },
            async go() {
                const to = this.$store.state.configuration.culture === 'en'
                    ? 'sv'
                    : 'en';

                this.$store.dispatch(actions.SET_LOCK, {
                    lock: true,
                    overlay: true
                });

                const route = this.$router.resolve({
                    name: 'home',
                    params: {
                        culture: to
                    },
                    query: {}
                });

                window.location.href = route.href;
            },
            onClickOutside() {
                this.bars.bar = false
            },
            onClickOutsideIncluded() {
                return [document.querySelector('.included')]
            }
        },
        onIdle() {
            console.log(this.$options.name, '=> onIdle');

            if (this.$store.state.user) {
                timerId = setTimeout(() => {
                    console.log(this.$options.name, '=> onIdle => timerId', timerId, 'timed out');
                    this.$store.commit(mutations.USER, null);
                }, this.$store.state.configuration.timeouts.user);
                
                console.log(this.$options.name, '=> onIdle => timerId', timerId, 'set to', this.$store.state.configuration.timeouts.user);
            }
        },
        onActive() {
            console.log(this.$options.name, '=> onActive', this.loading);
            console.log(this.$options.name, '=> onActive', this.$store.state.configuration.timeouts.user);

            if (timerId) {
                console.log(this.$options.name, '=> onActive => timerId', timerId, 'cleared');
                clearTimeout(timerId);
            }
        },
        mounted() {
            let recoScript = document.createElement('script');
            recoScript.setAttribute('src', 'https://widget.reco.se/yearsInRowBadge/4037942.js');
            document.head.appendChild(recoScript);

            this.$store.watch((state) => state.user, (u1, u2) => {
                if (this.$store.state.initialized) {
                    const user = u1 ?? u2;
                    const current = this.$router.history.current;

                    if (user && user.discountGroups.length > 0) {
                        this.$store.commit(mutations.CART_UPDATE, this.$store.state.cart);
                        
                        if (this.$route.meta.refresh?.login) {
                            this.$router.replace({
                                name: 'refresh'
                            }).then(() => {
                                this.$nextTick(function () {
                                    this.$router.replace(current)
                                });
                            });
                        }
                    }
                }
            }, {});

            this.$root.$on('global-notification', this.notification);

            //(function (d, w, c) {
            //    w.BrevoConversationsID = '60068b04d6ea0606c7296ce5';
            //    w[c] = w[c] || function () {
            //        (w[c].q = w[c].q || []).push(arguments);
            //    };
            //    var s = d.createElement('script');
            //    s.async = true;
            //    s.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
            //    if (d.head) d.head.appendChild(s);
            //})(document, window, 'BrevoConversations');
        },
        beforeDestroy() {
            this.$root.$off('global-notification', this.notification);
        }
    };
</script>

<style lang="scss">
    @import '~vuetify/src/styles/styles.sass';
    @import '~vuetify/src/styles/settings/_variables';

    .v-btn {
        .v-icon {
            &.custom-svg {
                background-size: contain;
                background-repeat: no-repeat;
                width: 30px;

                &.v-icon--right, &.v-icon--left {
                    width: 30px;
                    height: 30px;
                    outline-offset: 0px;
                    outline: 3px solid;
                    border: 3px solid transparent;
                    border-radius: 20px;
                }
            }
        }

        &--hover {
            &:hover {
                .v-icon {
                    &.v-icon--left, &.v-icon--right {
                        @media not all and (pointer: coarse) {
                            background-image: none !important;

                            .v-icon__svg {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &--home, &--loggin, &--search {
            border-bottom: 4px solid var(--v-primary-base);

            &:hover {
                .v-icon {
                    &.v-icon--left, &.v-icon--right {
                        @media not all and (pointer: coarse) {
                            outline-color: var(--v-primary-base);
                            border-color: var(--v-primary-lighten1);
                        }
                    }
                }
            }

            &:active {
                .v-icon {
                    &.v-icon--left, &.v-icon--right {
                        @media (pointer: coarse) {
                            background-image: none !important;
                            outline-color: var(--v-primary-base);
                            border-color: var(--v-primary-lighten1);

                            .v-icon__svg {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &--search {
            min-width: unset !important;
        }

        &--avian {
            border-bottom: 4px solid var(--v-avian-base);

            .v-icon {
                background-position-x: -1px;
            }

            &:hover {
                .v-icon {
                    &.v-icon--left, &.v-icon--right {
                        @media not all and (pointer: coarse) {
                            outline-color: var(--v-avian-base);
                            border-color: var(--v-avian-lighten1);
                        }
                    }
                }
            }

            &:active {
                .v-icon {
                    &.v-icon--left, &.v-icon--right {
                        @media (pointer: coarse) {
                            background-image: none !important;
                            outline-color: var(--v-avian-base);
                            border-color: var(--v-avian-lighten1);

                            .v-icon__svg {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    html {
        font-family: Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif !important;

        &.lock {
            overflow-y: hidden;
        }
    }

    .v-menu__content {
        &.fill-height {
            height: 100vh;

            &.top {

                .container {
                    height: 90%;

                    .v-card {
                        height: 100%;
                        overflow: scroll;
                    }
                }
            }
        }
    }

    /* This is for documentation purposes and will not be needed in your application */
    #create .v-speed-dial {
        //position: absolute;
        display: inline-block;
        max-width: 40px;
        position: fixed;
        right: 12px;
        top: 16px;
        z-index: 4;
    }

    #home-skeleton-loader {
        min-width: 80px;
    }

    #home-skeleton-loader {
        .v-skeleton-loader__button, .v-btn {
            width: 100%;
        }
    }

    .Vue-Toastification__toast--default.cart-toast {
        width: 100%;
        background-color: #7ec9aa;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} and (orientation:landscape) {
        .hidden-sm-landscape-and-down {
            display: none !important;
        }
    }

    .v-card__text, .v-card__title {
        word-break: normal; /* maybe !important  */
    }

    .v-cart-button {
        position: relative;
        overflow: hidden;
        height: 40px !important;
        color: #ffffff !important;
        font-weight: map-get($font-weights, bold);
    }
</style>

<style>
    .flashit {
        animation: flash linear 1s 3;
    }

    @keyframes flash {
        0% {
            opacity: 1;
        }

        50% {
            opacity: .1;
        }

        100% {
            opacity: 1;
        }
    }

    .shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
    }

    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>
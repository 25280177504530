<template>
    <v-skeleton-loader v-bind:loading="atc"
                       transition="expand-transition"
                       v-slot
                       type="card"
                       class="rounded-0 d-block">
        <v-card class="px-4">
            <v-card-text>
                <v-form id="reset"
                        ref="reset"
                        lazy-validation
                        @submit.prevent="validate">
                    <v-expand-transition>
                        <div v-if="message !== null">
                            <v-alert dense
                                     text
                                     type="warning">
                                {{ message?.description }}
                            </v-alert>
                        </div>
                    </v-expand-transition>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="username"
                                          v-bind:rules="[rules.required]"
                                          v-bind:label="$tcc('inputs.username.label')"
                                          v-bind:disabled="submitting"
                                          required
                                          autofocus
                                          validate-on-blur
                                          autocomplete="username" />
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <v-row v-if="key">
                            <v-col cols="12">
                                <v-text-field v-model="key"
                                              v-bind:rules="[rules.required]"
                                              v-bind:label="$tcc('inputs.reset-key.label')"
                                              type="text"
                                              disabled />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="code"
                                              v-bind:label="$tcc('inputs.reset-code.label')"
                                              v-bind:rules="[rules.required]"
                                              autofocus
                                              type="text" />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="password"
                                              v-bind:label="$tcc('inputs.password.label')"
                                              v-bind:type="passwordShow ? 'text' : 'password'"
                                              v-bind:append-icon="passwordShow ? icons.mdiEye : icons.mdiEyeOff"
                                              v-bind:rules="[rules.required, rules.password]"
                                              v-bind:hint="$tcg('password.hint', { 'min-characters': minCharacters })"
                                              v-on:click:append="passwordShow = !passwordShow"
                                              validate-on-blur
                                              counter
                                              required
                                              id="new-password"
                                              autocomplete="new-password"
                                              name="new-password"
                                              passwordrules="required: upper; required: lower; required: digit; minlength: 8; max-consecutive: 2;" />
                            </v-col>
                        </v-row>
                    </v-expand-transition>
                    <v-row>
                        <v-spacer />
                        <v-col class="d-flex"
                               cols="12"
                               align-end>
                            <v-btn v-bind:loading="submitting"
                                   large
                                   block
                                   color="primary"
                                   type="submit">
                                {{ $tcc('btns.reset.text') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-skeleton-loader>
</template>

<script>
    /* eslint-disable no-debugger */
    /* eslint-disable no-unused-vars */
    import VLogOnDialogFormBase from './LogOnDialogFormBase';

    import {
        i18nLoader
    } from '/src/mixins/commons';

    import {
        mdiEye,
        mdiEyeOff,
    } from '@mdi/js';

    import * as mutations from '/src/store/mutation-types';

    export default {
        name: 'log-on-dialog-reset-password-form',
        extends: VLogOnDialogFormBase,
        mixins: [
            i18nLoader,
        ],
        data: () => ({
            icons: {
                mdiEye,
                mdiEyeOff,
            },
            slug: '/account/password/reset',
            message: null,
            username: null,
            key: null,
            code: null,
            password: null,
            passwordShow: false,
        }),
        props: {
            minCharacters: {
                type: Number,
                default: 8
            }
        },
        watch: {
            key(value) {
                this.verb = value
                    ? 'put'
                    : 'post';
            }
        },
        methods: {
            form() {
                return {
                    username: this.username,
                    key: this.key,
                    code: this.code,
                    email: this.username,
                    password: this.password
                }
            },
            validateStatus(status) {
                return true;
            },
            response(response) {
                if (response.status === 200) {
                    if (this.verb === 'post') {
                        this.key = response.data.result;
                    } else if (this.verb === 'put') {
                        this.message = response.data.messages[0];
                        this.key = null;
                        this.code = null;
                        this.password = null;

                    }
                } else {
                    this.message = response.data.messages[0];
                }
            },
            resetPassword() {
                //this.login.selected = 2
                //this.forms.reset.username = this.$refs.username.value;
            }
        }
    }
</script>
/* eslint-disable no-debugger */

export const attributes = {
    methods: {
        hasAttribute(key, value) {
            if (value) {
                return this.attributes.some(attr => attr.key === key && attr.value === value);
            } else {
                return this.attributes.some(attr => attr.key === key);
            }
        },
        itemHasAttribute(item, key, value) {
            if (value) {
                return item.attributes.data.some(attr => attr.key === key && attr.value === value);
            } else {
                return item.attributes.data.some(attr => attr.key === key);
            }
        },
        discountAttributes() {
            return this.attributes.filter(attr => attr.key.startsWith('discount_group_'))
                .map(attr => {
                    return {
                        key: attr.key.replace('discount_group_', ''),
                        value: Number(attr.value.replace('none', 0))
                    };
                });
        },
        discountAttributeList() {
            const attr = this.discountAttributes();

            if (attr.length > 0) {
                var min = attr.reduce((a, b) => a.value < b.value ? a : b);
                var max = attr.reduce((a, b) => a.value > b.value ? a : b);

                return min.value == max.value
                    ? [{
                        key: min.key,
                        min: min.value,
                        max: null
                    }]
                    : [{
                        key: min.key,
                        min: min.value,
                        max: max.value
                    }]
            }

            return [];
        },
        itemDiscountAttributes(item) {
            return item.attributes.data.filter(attr => attr.key.startsWith('discount_group_'))
                .map(attr => {
                    return {
                        key: attr.key.replace('discount_group_', ''),
                        value: attr.value.replace('none', 0)
                    };
                });
        }
    }
}
import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from "../axios";
import i18n from '../plugins/i18n';
import * as paymentrefs from '../components/helpers/payment';
import * as actions from '../store/action-types';
import * as mutations from '/src/store/mutation-types';
import countries from '../assets/json/countries.json';

Vue.use(VueRouter)

const about = () => import(/* webpackChunkName: "views.about" */ '../views/About.vue');
const checkout = () => import(/* webpackChunkName: "views.checkout" */ '../views/Checkout.vue');
const error = () => import(/* webpackChunkName: "views.error" */ '../views/Error.vue');
const home = () => import(/* webpackChunkName: "views.home" */ '../views/Home.vue');
const account = () => import(/* webpackChunkName: "views.account" */ '../views/Account.vue');
const page = () => import(/* webpackChunkName: 'views.page' */ '../views/Page.vue');
const refresh = () => import(/* webpackChunkName: "views.refresh" */ '../views/Refresh.vue');
const shop = () => import(/* webpackChunkName: "views.shop" */ '../views/Shop.vue');

const defaultComponent = (to, from, next, component) => {
    to.params.component ??= component;

    next();
}

const routes = [
    {
        path: '/',
        name: 'home-base',
        component: home,
        meta: {
            refresh: {
                login: false
            }
        }
    },
    {
        path: '/:culture(sv|en)',
        name: 'home',
        component: home,
        meta: {
            refresh: {
                login: true
            },
            content: ''
        },
        beforeEnter: (to, from, next) => defaultComponent(to, from, next, 'index'),
        children: []
    },
    {
        path: '/:culture/:content(kassa||checkout)',
        name: 'store-checkout',
        component: checkout,
        meta: {
            refresh: {
                login: false
            }
        },
        beforeEnter: (to, from, next) => defaultComponent(to, from, next, 'checkout'),
    },
    {
        path: '/:culture/:content(kassa||checkout)/:confirm',
        name: 'store-checkout-confirm',
        component: about,
        redirect: function (to) {
            if (to.query.redirect_status === 'failed') {
                const route = router.resolve({
                    name: 'store-checkout',
                    params: {
                        content: to.params.content,
                        culture: to.params.culture
                    },
                    query: {
                        reference: to.query.payment_intent,
                        status: to.query.redirect_status,
                        key: to.query.id
                    },
                });

                return route.resolved;
            } else {
                const route = router.resolve({
                    name: 'store-checkout-confirmation',
                    params: {
                        culture: to.params.culture,
                        reference: to.query.reference,
                        key: to.query.key,
                        transaction: to.query.payment_intent,
                    },
                    query: {}
                });

                // Only on "succeeded" we remove the cart content and REFERENCE_KEY.
                if (to.query.redirect_status === 'succeeded') {
                    localStorage.removeItem('pipeline.cart');
                    sessionStorage.removeItem(paymentrefs.REFERENCE_KEY);
                }

                return route.resolved;
            }
        }
    },
    {
        path: '/:culture(sv|en)/:catalog(konto||account)*',
        name: 'account',
        component: account,
        meta: {
            refresh: {
                login: true
            },
            content: '{catalog}'
        },
        beforeEnter: (to, from, next) => defaultComponent(to, from, next, 'index'),
        children: []
    },
    {
        path: '/:culture(sv|en)/:catalog(butik||store)/*',
        name: 'store',
        component: shop,
        meta: {
            refresh: {
                login: true
            }
        },
        beforeEnter: (to, from, next) => defaultComponent(to, from, next, 'category-articles')
    },
    {
        //path: '/:culture/:catalog/:content/:confirmation/:reference/:key/:transaction',
        path: '/:culture/:confirmation/:reference/:key/:transaction',
        name: 'store-checkout-confirmation',
        component: shop,
        meta: {
            refresh: {
                login: false
            }
        }
    },
    {
        path: '/:culture(sv|en)/refresh/',
        name: 'refresh',
        component: refresh,
        meta: {
            refresh: {
                login: false
            }
        }
    },
    {
        path: '/:culture(sv|en)/*',
        name: 'page',
        component: page,
        meta: {
            refresh: {
                login: true
            }
        },
        beforeEnter: (to, from, next) => defaultComponent(to, from, next, 'index'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: error,
        meta: {
            refresh: {
                login: false
            }
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        console.log('VueRouter => scrollBehavior');
        return new Promise(resolve => {
            console.log('VueRouter => scrollBehavior => Promise');
            //if (to.hash) {
            //    scrollTo = to.hash
            //}

            if (history.state.position
                && history.state.position.y
                && to.fullPath !== from.fullPath
                && to.fullPath === history.state.url) {
                console.log('VueRouter => scrollBehavior => Promise => state.position');

                // create an Observer instance
                const resizeObserver = new ResizeObserver(entries => {
                    console.log('clientHeight:', entries[0].target.clientHeight, 'position.y:', history.state.position?.y, 'height:', screen.height, history.state.position?.y + screen.height);

                    if (entries[0].target.clientHeight >= history.state.position?.y + screen.height) {
                        this.app.$nextTick(() => {
                            console.log('VueRouter => scrollBehavior => Promise => state.position => $nextTick');
                            resolve(history.state.position);
                        });
                        resizeObserver.disconnect();
                    }
                });

                // start observing a DOM node
                resizeObserver.observe(document.body);
            }
            else if (savedPosition) {
                console.log('VueRouter => scrollBehavior => Promise => savedPosition');
                resolve(savedPosition);
                //// create an Observer instance
                //const resizeObserver = new ResizeObserver(entries => {
                //    let clientHeight = entries[0].target.clientHeight;
                //    let positionHeight = savedPosition.y + screen.height

                //    console.log(savedPosition, clientHeight, positionHeight);

                //    //const main = document.querySelectorAll('main.v-main');
                //    if (entries[0].target.clientHeight >= savedPosition.y + screen.height) {
                //        console.log(entries);
                //        resolve(savedPosition);
                //        resizeObserver.disconnect();
                //    }
                //});

                //// start observing a DOM node
                //resizeObserver.observe(document.body);
            } else {
                console.log('VueRouter => scrollBehavior => Promise => Default');
                resolve({ x: 0, y: 0 });
            }
        });
    }
});

const loadedLanguages = [] // our default language that is preloaded

async function loadLanguageAsync(lang) {
    const vuetify = await import(/* webpackChunkName: "vuetify-lang-[request]" */ `vuetify/lib/locale/${lang}.js`);

    await axios.get(`/api/content/${lang}/appdata`, { headers: { 'Accept': '*/*' } })
        .then(response => {
            response.data.configuration.countries = response.data.configuration.countries.map(code => countries[code]);

            let configuration = {
                ...response.data.configuration,
                ...{
                    culture: i18n.locale
                },
                client: {
                    ...router.app.$store.state.configuration.client,
                    ...response.data.configuration.client
                }
            }

            router.app.$store.commit(mutations.CONFIGURATION, configuration);

            router.app.$store.dispatch(actions.COUNTRY_UPDATE, response.data.user
                ? response.data.user.locale
                : response.data.configuration.country);

            if (response.data.user) {
                router.app.$store.commit(mutations.USER, response.data.user);
            }

            router.app.$store.commit(mutations.NAVIGATIONS, response.data.navigations);
            if ('pipeline.cart' in localStorage) router.app.$store.commit(mutations.CART_UPDATE, JSON.parse(localStorage.getItem('pipeline.cart')));
            router.app.$store.commit(mutations.INITIALIZED, true);
        });

    // If the language hasn't been loaded yet
    return import(/* webpackChunkName: "[request]-lang" */ `@/locales/app.${lang}`)
        .then(messages => {
            i18n.mergeLocaleMessage(lang, {
                $vuetify: vuetify.default,
                $pipeline: messages.default
            });
            loadedLanguages.push(lang);
            return lang;
        })
}

const match = router.matcher.match;

router.matcher.match = (raw, currentRoute, redirectedFrom) => {
    if (typeof raw === 'object' && !raw.matched) {
        const catalog = (() => {
            switch (router.app.$i18n.locale) {
                case 'sv':
                    return 'butik';
                default:
                    return 'store';
            }
        });

        if (raw.name === 'store-checkout') {
            raw.params = {
                ...raw.params,
                ...{
                    catalog: catalog(),
                    content: (() => {
                        switch (router.app.$i18n.locale) {
                            case 'sv':
                                return 'kassa';
                            default:
                                return 'checkout';
                        }
                    })()
                }
            };
        } else if (raw.name === 'store-checkout-confirm') {
            raw.params = {
                ...raw.params,
                ...{
                    catalog: catalog(),
                    content: (() => {
                        switch (router.app.$i18n.locale) {
                            case 'sv':
                                return 'kassa';
                            default:
                                return 'checkout';
                        }
                    })(),
                    confirm: (() => {
                        switch (router.app.$i18n.locale) {
                            case 'sv':
                                return 'konfirmera';
                            default:
                                return 'confirm';
                        }
                    })(),
                    hash: ''
                }
            };
        } else if (raw.name === 'store-checkout-confirmation') {
            // https://matomo.org/faq/reports/implement-event-tracking-with-matomo/
            //router.app.$matomo.trackEvent("Event Category", "Event Name", "event action");
            raw.params = {
                ...raw.params,
                ...{
                    content: (() => {
                        switch (router.app.$i18n.locale) {
                            case 'sv':
                                return 'kassa';
                            default:
                                return 'checkout';
                        }
                    })(),
                    confirmation: (() => {
                        switch (router.app.$i18n.locale) {
                            case 'sv':
                                return 'bestallningsbekraftelse';
                            default:
                                return 'order-confirmation';
                        }
                    })(),
                }
            };
        } else if (raw.name === 'store') {
            try {
                raw.params = {
                    ...raw.params,
                    ...{
                        catalog: catalog()
                    }
                };
            } catch (e) {
                // TODO: Handel.
            }
        } else if (raw.name === 'account') {
            try {
                raw.params = {
                    ...raw.params,
                    ...{
                        catalog: (() => {
                            switch (router.app.$i18n.locale) {
                                case 'sv':
                                    return 'konto';
                                default:
                                    return 'account';
                            }
                        })()
                    }
                };
            } catch (e) {
                // TODO: Handel.
            }
        }
    }

    return match(raw, currentRoute, redirectedFrom);
};

router.beforeEach(async (to, from, next) => {
    console.log('VueRouter => beforeEach');
    if (to.name !== 'error') {
        try {
            if (loadedLanguages.includes(to.params.culture)) {
                next();
            } else {
                await loadLanguageAsync(to.params.culture)
                    .then(() => next());
            }
        } catch (e) {
            await loadLanguageAsync(i18n.fallbackLocale) // Loading fallback language.
                .then(() => next());

            router.push({
                name: 'error',
                // preserve current path and remove the first char to avoid the target URL starting with `//`
                params: {
                    pathMatch: to.path.substring(1).split('/'),
                    error: 'http500'
                },
                // preserve existing query and hash if any
                query: to.query,
                hash: to.hash,
            })
            next();
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    console.log('VueRouter => afterEach');
    //if (router.app.$matomo) {
    //    debugger;
    //    var x = router.app.$matomo.setCustomUrl(to.fullPath);
    //    var y = router.app.$matomo.trackPageView(document.title);
    //}

    if (router.app.$store.state.locked) {
        // Locking up the app.
        router.app.$store.dispatch(actions.SET_LOCK, {
            lock: false,
            overlay: false
        });
    }

    // Updating current culture/locale.
    const $vuetify = router.app.$vuetify;
    const $i18n = router.app.$i18n;

    if (to.params.culture && $vuetify.lang.current !== to.params.culture) {
        $vuetify.lang.current = to.params.culture;
        $i18n.locale = to.params.culture;
    }
});

export default router;

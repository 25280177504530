/* eslint-disable no-debugger */
/* eslint-disable no-undef */

import Vue from 'vue';
import VueMatomo from 'vue-matomo';
import VueMeta from 'vue-meta';
import VueFlags from "@growthbunker/vueflags";
import VueSocialSharing from 'vue-social-sharing';
import IdleVue from 'idle-vue';
import App from './App.vue';
import axios from "./axios";
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import InstantSearch from 'vue-instantsearch';
//import VSnackbars from 'v-snackbars'
//import VueEllipseProgress from 'vue-ellipse-progress';

Vue.config.productionTip = false

const propsData = {
    ...document.querySelector('#app').dataset,
    ... {
        statusCode: parseInt(document.querySelector('#app').dataset.statusCode)
    }
}

Vue.use(VueMatomo, {
    host: 'https://zooingvar.matomo.cloud/',
    siteId: propsData.matomoId,
    requireConsent: true,
    requireCookieConsent: true
});
Vue.use(VueMeta);
Vue.use(VueFlags, {
    // Specify the path of the folder where the flags are stored.
    iconPath: '/images/icons/flags/',
});
Vue.use(VueSocialSharing, {
    networks: {
        fakeblock: 'https://fakeblock.com/share?url=@url&title=@title'
    }
});
Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    store,
    idleTime: 1000,
    startAtIdle: false
});
Vue.use(InstantSearch);

new Vue({
    axios,
    router,
    store,
    vuetify,
    i18n,
    propsData: propsData,
    props: {
        'status-code': {
            type: Number,
            required: true
        },
        'component': String,
        'path': String
    },
    render: h => h(App)
}).$mount('#app');
<template>
    <!-- Login -->
    <v-log-on-dialog v-if="!user" />
    <!-- ./Login -->
    <!-- Account -->
    <v-toolbar-items v-else>
        <v-btn v-bind:to="{ name: 'account', params: { culture: $i18n.locale } }"
               exact-path
               elevation="0"
               x-large
               plain
               class="v-btn--hover v-btn--loggin">
            {{ $store.state.user.givenName }}
            <v-icon v-html="icons.account"
                    class="custom-svg ml-3"
                    right />
        </v-btn>
    </v-toolbar-items>
    <!-- ./Account -->
</template>

<script>
    /* eslint-disable no-debugger */
    /* eslint-disable no-unused-vars */
    import { mapState } from 'vuex';

    import {
        i18nLoader
    } from '../mixins/commons'

    import {
        mdiAccountCircle,
        mdiAccountCircleOutline,
        mdiEye,
        mdiEyeOff,
    } from '@mdi/js'

    import * as actions from '../store/action-types';
    import * as mutations from '../store/mutation-types';

    const isNonWhiteSpace = /^\S*$/;
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    const isContainsNumber = /^(?=.*[0-9]).*$/;
    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/;
    const isValidLength = /^.{0,}$/;

    const validatePassword = (vm, value) => {
        if (!isNonWhiteSpace.test(value)) {
            return vm.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.no-whitespaces');
        }

        if (!isContainsUppercase.test(value)) {
            return vm.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.must-have-uppercase');
        }

        if (!isContainsLowercase.test(value)) {
            return vm.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.must-have-lowercase');
        }

        if (!isContainsNumber.test(value)) {
            return vm.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.must-have-digit');
        }

        if (!isContainsSymbol.test(value)) {
            return vm.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.must-have-special-characters'); // Characters vs Symbol?
        }

        if (!new RegExp(isValidLength.source.replace('0', vm.minCharacters)).test(value)) {
            return vm.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.must-be-x-characters', vm.minCharacters); // Characters vs Symbol?
        }

        return true;
    }

    import VLogOnDialog from '/src/components/account/logon/dialog/LogOnDialog';

    export default {
        name: 'app-account-toolbar-item',
        mixins: [
            i18nLoader,
        ],
        components: {
            'v-log-on-dialog': VLogOnDialog
        },
        data: () => ({
            temp: false,
            host: location.hostname,
            icons: {
                account: mdiAccountCircleOutline,
                accountCreate: mdiAccountCircle,
                mdiEye,
                mdiEyeOff
            },
            login: {
                active: false,
                selected: null
            },
            forms: {
                login: {
                    message: null,
                    username: null,
                    password: null,
                    passwordShow: false,
                    remember: false
                },
                register: {
                    password: null,
                    passwordShow: false,
                    confirm: null,
                    confirmShow: false
                },
                reset: {
                    message: null,
                    username: null
                },
                resetValidate: {
                    key: null,
                    code: null,
                    password: null,
                    passwordShow: false
                },
                submitting: false
            },
            valid: false,
        }),
        props: {
            minCharacters: {
                type: Number,
                default: 8
            }
        },
        computed: {
            ...mapState({
                user: state => state.user
            }),
            rules() {
                return {
                    required: value => !!value || this.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.required'),
                    //min: v => (v && v.length >= this.minCharacters) || this.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.min-characters', this.minCharacters),
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || this.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.invalid-e-mail'); //'Invalid e-mail.'
                    },
                    password: value => validatePassword(this, value)
                }
            },
            passwordMatch() {
                return () => this.forms.register.password === this.forms.register.confirm || this.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.password-must-match'); // "Password must match";
            }
        },
        watch: {
            'login.active'(value) {
                this.forms.login.message = null;

                this.$store.dispatch(actions.SET_LOCK, {
                    lock: value,
                    overlay: false
                });
            },
            'forms.login.message'(message) {
                if (message) {
                    message.targets.forEach((target) => {
                        let input = this.$refs[target.replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, '')];

                        if (input) {
                            input.errorBucket.push(message.title);
                        }
                    });
                }
                else {
                    this.$refs.login.validate();
                }
            }
        },
        methods: {
            resetPassword() {
                this.login.selected = 2
                this.forms.reset.username = this.$refs.username.value;

            },
            validate($event, x, y) {
                this.forms.submitting = true;
                const target = $event.target.id;
                debugger;
                try {

                    if (this.$refs[target].validate()) {
                        switch (target) {
                            case 'login': {
                                this.forms.login.message = null;

                                this.$http.post(`/api/${this.$route.params.culture}/account/logon`, this.forms.login, {
                                    validateStatus: function (status) {
                                        return status === 200 || status === 401;
                                    }
                                }).then(response => {
                                    switch (response.status) {
                                        case 200: {
                                            this.$store.commit(mutations.USER, response.data);
                                            this.login.active = false;
                                            break;
                                        }
                                        case 401: {
                                            this.forms.login.message = response.data.messages[0];
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                });
                                break;
                            }
                            case 'register': {
                                break;
                            }
                            case 'reset': {
                                this.forms.reset.message = null;

                                this.$http.post(`/api/${this.$route.params.culture}/account/password/reset`, this.forms.reset, {
                                    validateStatus: function (status) {
                                        return status === 200;
                                    }
                                }).then(response => {
                                    switch (response.status) {
                                        case 200: {
                                            this.forms.resetValidate.key = response.data.result;
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                });
                                break;
                            }
                            default:
                                break;
                        }
                    }
                } catch (e) {
                    debugger;
                } finally {
                    this.forms.submitting = false;
                }
            },
            //reset() {
            //    this.$refs.form.reset();
            //},
            //resetValidation() {
            //    this.$refs.form.resetValidation();
            //}
        }
    }
</script>

<style lang="scss">

</style>
<template>
    <v-toolbar-items v-if="!$store.state.user">
        <v-dialog v-model="active"
                  max-width="600px"
                  min-width="360px"
                  content-class="v-responsive">
            <template v-slot:activator="{ on, attrs }">
                <v-btn elevation="0"
                       v-bind:loading="atc"
                       x-large
                       plain
                       class="v-btn--hover v-btn--loggin font-weight-bold"
                       v-bind="attrs"
                       v-on="on">
                    {{ $tcc('btns.logon.text') }}
                    <v-icon v-html="icons.account"
                            class="custom-svg ml-3"
                            right />
                </v-btn>
            </template>
            <v-tabs v-model="selected"
                    show-arrows
                    background-color="primary accent-4"
                    icons-and-text
                    dark
                    grow>
                <v-tabs-slider color="black" />
                <!-- Tabs -->
                <v-tab v-bind:disabled="$store.state.user !== null"
                       style="width:50%;">
                    <v-icon v-html="icons.account"
                            x-large />
                    <div class="caption py-1">
                        {{ $tcc('tabs.logon.caption') }}
                    </div>
                </v-tab>
                <v-tab v-bind:disabled="$store.state.user !== null"
                       style="width:50%;">
                    <v-icon v-html="icons.accountCreate"
                            x-large />
                    <div class="caption py-1">
                        {{ $tcc('tabs.create.caption') }}
                    </div>
                </v-tab>
                <v-tab class="d-none">
                    <v-icon v-html="icons.account"
                            x-large />
                    <div class="caption py-1">
                        [Reset]
                    </div>
                </v-tab>
                <!-- ./Tabs -->
                <!-- Tab items -->
                <v-tab-item eager>
                    <v-log-on-dialog-log-on-tab-form @hook:updated="loading = false"
                                                     @password-reset="reset" />
                </v-tab-item>
                <v-tab-item eager>
                    <v-log-on-dialog-create-account-form />
                </v-tab-item>
                <v-tab-item eager>
                    <v-log-on-dialog-reset-password-form ref="reset" />
                </v-tab-item>
            </v-tabs>
        </v-dialog>
    </v-toolbar-items>
</template>

<script>
    /* eslint-disable no-debugger */
    /* eslint-disable no-unused-vars */
    import { mapState, mapActions } from 'vuex';
    import VLogOnDialogLogOnForm from './LogOnDialogLogOnForm';
    import VLogOnDialogCreateAccountForm from './LogOnDialogCreateAccountForm';
    import VLogOnDialogResetPasswordForm from './LogOnDialogResetPasswordForm';

    import {
        i18nLoader
    } from '/src/mixins/commons';

    import {
        mdiAccountCircle,
        mdiAccountCircleOutline,
    } from '@mdi/js';

    import * as actions from '/src/store/action-types';

    export default {
        name: 'log-on-dialog',
        mixins: [
            i18nLoader,
        ],
        components: {
            'v-log-on-dialog-log-on-tab-form': VLogOnDialogLogOnForm, //() => import(/* webpackChunkName: 'log-on-dialog-log-on-form' */ './LogOnDialogLogOnForm'),
            'v-log-on-dialog-create-account-form': VLogOnDialogCreateAccountForm, //() => import(/* webpackChunkName: 'log-on-dialog-reset-password-form' */ './LogOnDialogResetPasswordForm'),
            'v-log-on-dialog-reset-password-form': VLogOnDialogResetPasswordForm, //() => import(/* webpackChunkName: 'log-on-dialog-reset-password-form' */ './LogOnDialogResetPasswordForm'),
        },
        data: () => ({
            icons: {
                account: mdiAccountCircleOutline,
                accountCreate: mdiAccountCircle,
            },
            loading: true,
            selected: null
        }),
        computed: {
            active: {
                ...mapState({ get: state => state.ui.logon }),
                ...mapActions({ set: actions.UI_LOGON }),
            },
        },
        methods: {
            reset(data) {
                this.$refs.reset.username = data.username;
                this.selected = 2;
            },
        }
    }
</script>

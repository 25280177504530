<template>
    <v-card class="px-4">
        <v-overlay v-bind:value="atc"
                   opacity="1"
                   color="white"
                   absolute>
            <v-progress-circular indeterminate
                                 color="primary"
                                 size="64" />
        </v-overlay>
        <v-card-text>
            <v-form id="login"
                    ref="login"
                    lazy-validation
                    @submit.prevent="validate">
                <v-expand-transition>
                    <div v-if="message !== null">
                        <v-alert dense
                                 text
                                 type="warning">
                            {{ message?.description }}<br />
                            {{ message?.targets }}
                        </v-alert>
                    </div>
                </v-expand-transition>
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="username"
                                      v-bind:disabled="submitting"
                                      v-bind:label="$tcc('inputs.e-mail.label')"
                                      v-bind:rules="[rules.required, rules.targets('Username')]"
                                      required
                                      autofocus
                                      autocomplete="username" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password"
                                      v-bind:append-icon="passwordShow ? icons.mdiEye : icons.mdiEyeOff"
                                      v-bind:disabled="submitting"
                                      v-bind:label="$tcc('inputs.password.label')"
                                      v-bind:rules="[rules.required, rules.targets('Password')]"
                                      v-bind:type="passwordShow ? 'text' : 'password'"
                                      v-on:click:append="passwordShow = !passwordShow"
                                      counter
                                      autocomplete="current-password" />
                    </v-col>
                    <v-col class="d-flex"
                           cols="12"
                           sm="5"
                           order="1"
                           order-sm="0">
                        <v-btn v-on:click="$emit('password-reset', { username: username })"
                               large
                               text
                               color="primary">
                            {{ $tcc('btns.reset.text') }}
                        </v-btn>
                    </v-col>
                    <v-spacer />
                    <v-col class="d-flex"
                           cols="12"
                           sm="5"
                           order="0"
                           order-sm="1"
                           align-end>
                        <v-btn v-bind:loading="submitting"
                               large
                               block
                               color="primary"
                               type="submit">
                            {{ $tcc('btns.logon.text') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    /* eslint-disable no-debugger */
    /* eslint-disable no-unused-vars */
    import VLogOnDialogFormBase from './LogOnDialogFormBase';

    import {
        i18nLoader
    } from '/src/mixins/commons';

    import {
        mdiEye,
        mdiEyeOff,
    } from '@mdi/js';

    import * as actions from '/src/store/action-types';
    import * as mutations from '/src/store/mutation-types';

    export default {
        name: 'log-on-dialog-log-on-form',
        extends: VLogOnDialogFormBase,
        mixins: [
            i18nLoader,
        ],
        data: () => ({
            icons: {
                mdiEye,
                mdiEyeOff,
            },
            slug: '/account/logon',
            username: null,
            password: null,
            passwordShow: false
        }),
        methods: {
            form() {
                return {
                    username: this.username,
                    password: this.password
                }
            },
            validateStatus(status) {
                return status === 200 || status === 401;
            },
            response(response) {
                switch (response.status) {
                    case 200: {
                        this.$store.dispatch(actions.UI_LOGON, false);
                        this.$store.commit(mutations.USER, response.data);
                        this.$store.dispatch(actions.COUNTRY_UPDATE, response.data.locale);
                        break;
                    }
                    case 401: {
                        this.message = response.data.messages[0];
                        break;
                    }
                    default:
                        break;
                }
            },
            resetPassword() {
                //this.login.selected = 2
                //this.forms.reset.username = this.$refs.username.value;
            }
        }
    }
</script>
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import * as paymentrefs from '/src/components/helpers/payment';
import * as mutations from './mutation-types';
import * as actions from './action-types';
import { getPrice } from '/src/mixins/commons/prices';
import { merge } from '/src/mixins/commons/objects';

Vue.use(Vuex);

/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */

const equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) {
        return false;
    }

    const uniqueValues = new Set([...a, ...b]);

    for (const v of uniqueValues) {
        const aCount = a.filter(e => e === v).length;
        const bCount = b.filter(e => e === v).length;

        if (aCount !== bCount) {
            return false;
        }
    }

    return true;
}

export default new Vuex.Store({
    state: {
        initialized: false,
        configuration: {
            client: {
                search: {
                    index: null
                },
                environment: process.env.NODE_ENV,
                isProduction() {
                    return process.env.NODE_ENV === 'production';
                }
            }
        },
        navigations: [],
        locked: false,
        overlay: false,
        fabilize: true,
        fabs: false,
        ui: {
            // TODO: Move all UI flags to this object.
            logon: false
        },
        country: null,
        user: null,
        cart: {
            cost: 0,
            culture: null,
            items: [],
            processing: false,
            quantity: 0,
            shipping: null,
            valid: false
        },
        pricing: {
            countryId: 'SE',
            currencyId: 'SEK',
            includeVat: true,
        },
        filters: {
            birdsize: {
                type: 'attibute',
                display: 'switches',
                values: [
                    { text: 't', value: 't' },
                    { text: 'xs', value: 'xs' },
                    { text: 's', value: 's' },
                    { text: 'm', value: 'm' },
                    { text: 'l', value: 'l' },
                    { text: 'xl', value: 'xl' },
                    { text: 'xl+', value: 'xl+' },
                    { text: 'xxl', value: 'xxl' }
                ],
                cols: {
                    xs: 12,
                    sm: null,
                    md: null,
                    lg: null,
                    xl: null
                }
            },
        }
    },
    mutations: {
        [mutations.INITIALIZED]: (state, value) => state.initialized = value,
        [mutations.CONFIGURATION]: (state, value) => state.configuration = value,
        [mutations.NAVIGATIONS]: (state, value) => state.navigations = value,
        [mutations.COUNTRY]: (state, value) => {
            state.configuration.country = value.toUpperCase();
            state.country = state.configuration.countries.find(country => country.alpha2 === state.configuration.country);
        },
        [mutations.PRICING]: (state, value) => state.pricing = value,
        [mutations.LOCKED]: (state, value) => state.locked = value,
        [mutations.OVERLAY]: (state, value) => state.overlay = value,
        [mutations.FABILIZE]: (state, value) => state.fabilize = value,
        [mutations.FABS]: (state, value) => state.fabs = value,
        [mutations.USER]: (state, user) => {
            console.log(`store => mutations => ${mutations.USER}`, user);
            if (user) {
                console.log('store => mutations', '=>', mutations.USER, 'set');
                state.user = user;
            } else if (state.user !== null) {
                console.log('store => mutations', '=>', mutations.USER, 'logout');

                axios.post(`/api/sv/account/logoff`)
                    .then(response => {
                        if (response.status === 200) {
                            console.log('store => mutations', '=>', mutations.USER, 'cleared');
                            state.user = null;
                            sessionStorage.removeItem(paymentrefs.CHECKOUT_FORM);
                        }
                    });
            }
        },
        [mutations.CART_SAVE](state, payload) {
            console.log(`store => mutations => ${mutations.CART_SAVE}`, payload);
            if (payload && payload.items && payload.items.length > 0) {
                state.cart = {
                    ...payload,
                    quantity: payload
                        ? payload.items.reduce((accumulator, item) => accumulator + item.quantity, 0)
                        : 0
                }
            }

            this.commit(mutations.CART_CALCULATE);
            
            localStorage.setItem('pipeline.cart', JSON.stringify(state.cart));
        },
        async [mutations.CART_UPDATE](state, payload) {
            console.log(`store => mutations => ${mutations.CART_UPDATE}`, payload);
            payload.culture ??= state.configuration.culture;
            
            if (payload.culture !== state.configuration.culture) {
                state.cart.processing = true;

                payload = {
                    ...payload,
                    culture: state.configuration.culture
                };

                let response = await axios.post('/api/cart/update', payload, {});

                if (response.status === 200) {
                    payload.items = merge(payload.items, response.data.result.items);
                }

                state.cart.processing = false;
            }

            if (payload.items && payload.items.length > 0) {
                state.cart.processing = true;
                
                axios.post(`/api/cart/${state.pricing.countryId}/check`.toLocaleLowerCase(), payload, {})
                    .then(response => response.data.result)
                    .then(validations => {
                        console.log(`store => mutations => ${mutations.CART_UPDATE} => axios => THEN`, payload);
                        // Checking if the cart is valid.
                        payload.valid = !validations.some(v => v.reason.toLowerCase() === 'availability');

                        validations.forEach((validation) => {
                            validation.targets.forEach((target) => {
                                const item = payload.items.find(item => item.article.id === target.articleId &&
                                    item.products.some(product => product.productId === target.productId && product.discriminator === target.discriminator));

                                const product = item.products.find(product => product.productId === target.productId && product.discriminator === target.discriminator);
                                const reason = validation.reason.toLowerCase();

                                switch (reason) {
                                    case 'availability': {
                                        item.validation = {
                                            valid: false,
                                            message: reason,
                                            data: {
                                                validationName: validation.name,
                                                disposable: validation.disposable,
                                                wants: validation.wants,
                                                productCode: product.productCode,
                                                productName: product.productName,
                                                subtitle: product.subtitle
                                            }
                                        };
                                        break;
                                    }
                                    case 'availabilityorderitem': {
                                        item.validation = {
                                            valid: true,
                                            message: reason,
                                            data: {
                                                validationName: validation.name,
                                                disposable: validation.disposable,
                                                wants: validation.wants,
                                                productCode: product.productCode,
                                                productName: product.productName,
                                                subtitle: product.subtitle
                                            }
                                        };
                                        break;
                                    }
                                    case 'discount': {
                                        item.hasDiscount = validation.hasDiscount;
                                        item.validation.name = validation.name;
                                        product.prices = validation.targets[0].prices;
                                        break;
                                    }
                                    default:
                                        break;
                                }
                            });
                        });
                    }).finally(() => {
                        this.commit(mutations.CART_SAVE, payload);
                        state.cart.processing = false;
                    });

            } else {
                state.cart = {
                    ...state.cart,
                    cost: 0,
                    items: [],
                    processing: false,
                    quantity: 0,
                    shipping: null,
                    valid: false
                }
            }

            console.log(`store => mutations => ${mutations.CART_UPDATE} => END`, payload);
        },
        [mutations.CART_ADD](state, payload) {
            console.log(`store => actions => ${actions.CART_ADD}`, payload);

            const current = state.cart.items.find(ci => ci.article.id === payload.article.id
                ? equalsIgnoreOrder(ci.products.map(p => p.discriminator), payload.products.map(p => p.discriminator))
                : false);

            if (current) {
                current.quantity += payload.quantity;
            } else {
                state.cart.items.push({
                    article: {
                        id: payload.article.id,
                        discriminator: [payload.article.id].concat(payload.products.map(product => product.discriminator)).join('-'),
                        code: payload.article.code,
                        name: payload.article.name,
                        url: payload.article.canonical
                    },
                    products: payload.products.map(product => ({
                        productId: product.productId,
                        discriminator: product.discriminator,
                        productCode: product.productCode,
                        productBarcode: product.productBarcode,
                        productName: product.productName,
                        productSubtitle: product.subtitle,
                        manufacturerName: product.manufacturerName,
                        image: product.image,
                        images: product.images,
                        prices: product.prices,
                        quantity: product.quantity,
                        weightInGrams: product.weightinGrams
                    })),
                    quantity: payload.quantity,
                    hasDiscount: payload.products.some(product => product.isOnCampaign || product.isOffer),
                    validation: {
                        valid: true,
                        message: null
                    }
                });
             }

             this.commit(mutations.CART_UPDATE, state.cart);
        },
        [mutations.CART_REMOVE](state, payload) {
            console.log(`store => actions => ${actions.CART_REMOVE}`, payload);

            const current = state.cart.items.find(ci => ci.article.id === payload.article.id
                ? equalsIgnoreOrder(ci.products.map(p => p.discriminator), payload.products.map(p => p.discriminator))
                : false);

            if (current) {
                current.quantity -= payload.quantity;

                if (current.quantity <= 0) {
                    let idx = state.cart.items.indexOf(current);
                    state.cart.items.splice(idx, 1);
                }
            }

            this.commit(mutations.CART_UPDATE, state.cart);

        },
        [mutations.CART_SHIPPING](state, payload) {
            console.log(`store => mutations => ${mutations.CART_SHIPPING}`, payload);
            state.cart.shipping = payload;
            this.commit(mutations.CART_CALCULATE);
        },
        [mutations.CART_CALCULATE]: (state) => {
            console.log(`store => mutations => ${mutations.CART_CALCULATE}`);
            const prices = state.cart.items.map(ci => {
                let price = ci.products.map(product => getPrice(
                    product,
                    null,
                    state.pricing.includeVat,
                    state.pricing.countryId,
                    state.pricing.currencyId) * product.quantity);

                return price * ci.quantity;
            });

            if (state.cart.shipping) {
                prices.push(getPrice(
                    state.cart.shipping,
                    null,
                    state.pricing.includeVat,
                    state.pricing.countryId,
                    state.pricing.currencyId));
            }
            state.cart.cost = Math.round((prices.reduce((accumulator, price) => accumulator + price, 0) + Number.EPSILON) * 100) / 100;
        }
    },
    actions: {
        [actions.SET_LOCK]({ commit, state }, payload) {
            console.log(`store => actions => ${actions.SET_LOCK}`, payload);
            if (payload.lock !== state.locked) {
                const app = document.getElementById('app');
                const appBar = [...document.querySelectorAll('header.v-toolbar.v-app-bar')][0];
                const fabs = [...document.querySelectorAll('.v-btn.cart-fabs,.v-badge.cart-fabs')];

                if (payload.lock) {
                    app.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
                    appBar.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`;
                    fabs.forEach(fab => {
                        fab.style.right = `${parseInt(fab.style.right, 10) + (window.innerWidth - document.documentElement.clientWidth)}px`;
                    });
                    document.documentElement.classList.add('lock');
                } else {
                    setTimeout(() => {
                        app.style.paddingRight = '';
                        appBar.style.paddingRight = '';

                        fabs.forEach(fab => {
                            fab.style.right = '12px';
                        });

                        var event1 = document.createEvent('HTMLEvents');
                        event1.initEvent('scroll', true, true);

                        document.dispatchEvent(event1);
                        document.documentElement.classList.remove('lock');
                    }, 250);
                }

                commit(mutations.LOCKED, payload.lock);
                commit(mutations.OVERLAY, payload.overlay);
            }
        },
        [actions.CART_SHIPPING]({ commit }, payload) {
            console.log(`store => actions => ${actions.CART_SHIPPING}`, payload);
            commit(mutations.CART_SHIPPING, payload);
        },
        [actions.COUNTRY_UPDATE]({ commit, state }, payload) {
            console.log(`store => actions => ${actions.COUNTRY_UPDATE}`, payload);
            commit(mutations.COUNTRY, payload);
            commit(mutations.PRICING, {
                countryId: state.country.alpha2,
                currencyId: state.country.currency,
                includeVat: state.pricing.includeVat
            });
        },
        [actions.UI]({ state }, payload) {
            state.ui[payload.element] = payload.value;
        },
        [actions.UI_LOGON]({ dispatch }, payload) {
            dispatch(actions.UI, { element: 'logon', value: payload });
        }
    },
    modules: {
    }
})

import Vue from 'vue';
import i18n from './i18n'
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    lang: {
        locales: undefined,
        t: (key, ...params) => i18n.t(key, params)
    },
    icons: {
        iconfont: 'mdiSvg',
    },
    theme: {
        options: {
            customProperties: true,
            minifyTheme,
            themeCache: {
                get: key => localStorage.getItem(key),
                set: (key, value) => localStorage.setItem(key, value),
            },
        },
        dark: false,
        themes: {
            light: {
                primary: '#7ec9aa', //colors.teal.lighten2,
                avian: '#e69527',
                hedgehog: colors.lightGreen.base
                //secondary: '#b0bec5',
                //accent: '#8c9eff',
                //error: '#b71c1c',
            }
        },
    }
});

export default vuetify;

export const INITIALIZED = 'INITIALIZED';
export const CONFIGURATION = 'CONFIGURATION';
export const NAVIGATIONS = 'NAVIGATIONS';
export const COUNTRY = 'COUNTRY';
export const PRICING = 'PRICING';
export const LOCKED = 'LOCKED';
export const OVERLAY = 'OVERLAY';
export const FABILIZE = 'FABILIZE';
export const FABS = 'FABS';
export const USER = 'USER';
export const CART_SAVE = 'CARTSAVE';
export const CART_UPDATE = 'CARTUPDATE';
export const CART_ADD = 'CART_ADD';
export const CART_REMOVE = 'CART_REMOVE';
export const CART_SHIPPING = 'CARTSHIPPING';
export const CART_CALCULATE = 'CARTCALCULATE';
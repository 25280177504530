/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */

import { objects } from './objects';

export const storage = {
    mixins: [
        objects
    ],
    methods: {
        setSessionObject(key, path, value) {
            console.log('storage:', this.$options.name, '=> setSessionObject =>', key, { path, value });

            let obj = JSON.parse(sessionStorage.getItem(key)) ?? {};
            this.setValueByPath(obj, path, value);

            sessionStorage.setItem(key, JSON.stringify(obj));
        },
        getSessionObject(key) {
            console.log('storage:', this.$options.name, '=> getSessionObject =>', key);
            
            return JSON.parse(sessionStorage.getItem(key));
        }
    }
}
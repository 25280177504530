/* eslint-disable no-debugger */

export const shake = {
    data: () => ({
        shake: false
    }),
    watch: {
        '$store.state.cart.quantity'() {
            if (!this.shake) {
                this.shake = true;
                setTimeout(() => {
                    this.shake = false
                }, 820);
            }
        }
    }
}
//const merge = (obj1, obj2) => Object.entries(obj2).reduce((o, [k, v]) => {
//    if (k in o) {
//        if (!Array.isArray(o[k])) {
//            o[k] = [o[k]];
//        }
//        [].concat(v).forEach(item => {
//            if (!o[k].includes(item)) o[k].push(item);
//        });
//    } else {
//        o[k] = v;
//    }
//    return o;
//}, obj1);

export const merge = (obj1, obj2) => {
    for (var p in obj2) {
        try {
            // Property in destination object set; update its value.
            if (obj2[p].constructor == Object) {
                obj1[p] = merge(obj1[p], obj2[p]);

            } else {
                obj1[p] = obj2[p];
            }

        } catch (e) {
            // Property in destination object not set; create it and set its value.
            obj1[p] = obj2[p];

        }
    }

    return obj1;
}

const getValueByPath = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

function setValueByPath(obj, path, value) {
    let i;
    path = path.split('.');

    for (i = 0; i < path.length - 1; i++) {
        obj = obj[path[i]] ?? (obj[path[i]] = {});
    }

    obj[path[i]] = value;
}

export const objects = {
    methods: {
        merge(obj1, obj2) {
            return merge(obj1, obj2);
        },
        getValueByPath(root, path) {
            return getValueByPath(root, path);
        },
        setValueByPath(obj, path, value) {
            return setValueByPath(obj, path, value);
        }
    }
}
import * as mutations from '/src/store/mutation-types';

export const cart = {
    methods: {
        cartAdd(article, products, quantity) {
            this.cartMutate(mutations.CART_ADD, article, products, quantity);
        },
        cartRemove(article, products, quantity) {
            this.cartMutate(mutations.CART_REMOVE, article, products, quantity);
        },
        cartMutate(mutation, article, products, quantity) {
            this.$store.commit(mutation, {
                article: article,
                products: products,
                quantity: quantity
            });

            if (this.$route.name.endsWith('-checkout')) {
                return;
            } else {
                this.$root.$emit('global-notification', { article, products, quantity });
            }
        }
    }
}
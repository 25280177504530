import { attributes as commonattributes } from './commons/attributes'
import { cart as commoncart } from './commons/cart'
import { i18nLoader as commoni18nloader } from './commons/i18n-loader'
import { numbers as commonsnumbers } from './commons/numbers';
import { objects as commonsobjects } from './commons/objects';
import { prices as commonsprices } from './commons/prices';
import { shake as commonshake } from './commons/animations'
import { storage as commonstorage } from './commons/storage'

export const attributes = commonattributes;
export const cart = commoncart;
export const i18nLoader = commoni18nloader;
export const numbers = commonsnumbers;
export const objects = commonsobjects;
export const prices = commonsprices;
export const shake = commonshake;
export const storage = commonstorage;
/* eslint-disable no-debugger */
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios';
import router from '../router';

Vue.use(VueAxios, axios);

axios.defaults.headers.common['X-Requested-With'] = 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const instance = axios.create({});

axios.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        debugger;
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // Cancelation should not display an error.
        if (!axios.isCancel(error)) {
            router.replace({
                name: 'error',
                params: {
                    ...router.app.$route.params,
                    pathMatch: router.app.$route.path.substring(1).split('/'),
                    response: {
                        data: {
                            ...error.response.data,
                            status: error.response.status,
                        }
                    }
                },
                query: router.app.$route.query,
                hash: router.app.$route.hash
            }).catch(() => { }); // TODO: Find better way to solve 'Avoided redundant navigation to current location' error/warning.
        }

        return Promise.reject(error);
    });

export default instance;
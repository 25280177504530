/* eslint-disable no-debugger */

export const numbers = {
    methods: {
        truncate(number, scale, locale = 'sv') {
            var multiplier = Math.pow(10, scale),
                adjustedNum = number * multiplier,
                truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);

            return (truncatedNum / multiplier).toLocaleString(locale);
        },
        decimal(number, scale, locale = this.$i18n.locale) {    
            return (Math.round(number * 100) / 100).toLocaleString(locale, {
                minimumFractionDigits: scale,
                maximumFractionDigits: scale
            });
        }
    }
};
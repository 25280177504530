<template>
    <v-card class="px-4">
        <v-overlay v-bind:value="atc"
                   opacity="1"
                   color="white"
                   absolute>
            <v-progress-circular indeterminate
                                 color="primary"
                                 size="64" />
        </v-overlay>
        <v-card-text>
            <v-form v-model="valid"
                    id="register"
                    ref="register"
                    lazy-validation
                    @submit.prevent="validate">
                <v-row>
                    <v-col cols="12">
                        <v-select v-model="country"
                                  v-bind:items="countries"
                                  v-bind:label="$tcc('inputs.country.label')"
                                  item-value="alpha2"
                                  autofocus>
                            <template #prepend>
                                <gb-flag v-bind:code="country"
                                         size="small" />
                            </template>
                            <template #selection="{ item }">
                                {{ $tcg(`country.${item.alpha2}.name`) }}
                            </template>
                            <template #item="{ item }">
                                <gb-flag v-bind:code="item.alpha2"
                                         size="small"
                                         class="mr-2" />
                                {{ $tcg(`country.${item.alpha2}.name`) }}
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="givenName"
                                      v-bind:label="$tcc('inputs.given-name.label')"
                                      v-bind:rules="[rules.required]"
                                      validate-on-blur
                                      required />
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="familyName"
                                      v-bind:label="$tcc('inputs.family-name.label')"
                                      v-bind:rules="[rules.required]"
                                      validate-on-blur
                                      required />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="email"
                                      v-bind:label="$tcc('inputs.e-mail.label')"
                                      v-bind:rules="[rules.required, rules.email]"
                                      validate-on-blur
                                      required
                                      autocomplete="username"
                                      id="email"
                                      name="email"
                                      type="email"
                                      @blur="email = email.toLowerCase()"
                                      class="lower" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="password"
                                      v-bind:label="$tcc('inputs.password.label')"
                                      v-bind:type="passwordShow ? 'text' : 'password'"
                                      v-bind:append-icon="passwordShow ? icons.mdiEye : icons.mdiEyeOff"
                                      v-bind:rules="[rules.required, rules.password]"
                                      v-bind:hint="$tcg('password.hint', { 'min-characters': minCharacters })"
                                      v-on:click:append="passwordShow = !passwordShow"
                                      validate-on-blur
                                      counter
                                      required
                                      id="new-password"
                                      autocomplete="new-password"
                                      name="new-password"
                                      passwordrules="required: upper; required: lower; required: digit; minlength: 8; max-consecutive: 2;" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="d-flex ml-auto" cols="12" sm="5" xs="12">
                        <v-btn v-bind:loading="submitting"
                               v-bind:disabled="!valid"
                               large
                               block
                               color="primary"
                               type="submit">
                            {{ $tcc('inputs.register-btn.text') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
    /* eslint-disable no-debugger */
    /* eslint-disable no-unused-vars */
    import VLogOnDialogFormBase from './LogOnDialogFormBase';

    import {
        i18nLoader
    } from '/src/mixins/commons';

    import * as actions from '/src/store/action-types';
    import { mapState, mapActions } from 'vuex';

    import {
        mdiEye,
        mdiEyeOff,
    } from '@mdi/js';

    export default {
        name: 'log-on-dialog-create-account-form',
        extends: VLogOnDialogFormBase,
        mixins: [
            i18nLoader,
        ],
        data: () => ({
            icons: {
                mdiEye,
                mdiEyeOff,
            },
            slug: '/account/create',
            message: null,
            givenName: null,
            familyName: null,
            email: null,
            password: null,
            passwordShow: false,
            valid: false,
        }),
        props: {
            minCharacters: {
                type: Number,
                default: 8
            }
        },
        computed: {
            ...mapState({
                countryInfo: state => state.country,
                countries: state => state.configuration.countries,
            }),
            country: {
                ...mapState({ get: state => state.configuration.country }),
                ...mapActions({ set: actions.COUNTRY_UPDATE }),
            },
        },
        methods: {
            form() {
                return {
                    password: this.password,
                    customer: {
                        countryCode: this.country,
                        givenName: this.givenName,
                        familyName: this.familyName,
                        email: this.email,
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep(.lower) {
        input {
            text-transform: lowercase;
        }
    }
</style>
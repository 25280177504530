<template>
    <div>EMPTY</div>
</template>

<script>
    /* eslint-disable no-debugger */
    /* eslint-disable no-unused-vars */
    import {
        i18nLoader
    } from '/src/mixins/commons';

    //import * as actions from '../store/action-types';
    //import * as mutations from '/src/store/mutation-types';

    const isNonWhiteSpace = /^\S*$/;
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    const isContainsNumber = /^(?=.*[0-9]).*$/;
    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/;
    const isValidLength = /^.{0,}$/;

    const validatePassword = (vm, value) => {
        if (!isNonWhiteSpace.test(value)) {
            return vm.$tcg('inputs.validations.password.no-whitespaces');
        }

        if (!isContainsUppercase.test(value)) {
            return vm.$tcg('inputs.validations.password.must-have-uppercase');
        }

        if (!isContainsLowercase.test(value)) {
            return vm.$tcg('inputs.validations.password.must-have-lowercase');
        }

        if (!isContainsNumber.test(value)) {
            return vm.$tcg('inputs.validations.password.must-have-digit');
        }

        if (!isContainsSymbol.test(value)) {
            return vm.$tcg('inputs.validations.password.must-have-special-characters'); // Characters vs Symbol?
        }

        if (!new RegExp(isValidLength.source.replace('0', vm.minCharacters)).test(value)) {
            debugger;
            return vm.$tcg('inputs.validations.password.must-be-x-characters', { 'min-characters': vm.minCharacters }); // Characters vs Symbol?
        }

        return true;
    }

    export default {
        name: 'log-on-dialog-form-base',
        mixins: [
            i18nLoader,
        ],
        data: () => ({
            icons: {

            },
            verb: 'post',
            slug: '',
            message: null,
            submitting: false
        }),
        methods: {
            form() {
                throw 'Method "form" needs to be implemented!'
            },
            response() {
                throw 'Method "response" needs to be implemented!'
            },
            validate($event) {
                this.message = null;
                const target = $event.target.id;

                if (this.$refs[target].validate()) {
                    this.submitting = true;

                    this.$http[this.verb](
                        `/api/${this.$route.params.culture}${this.slug}`,
                        this.form(),
                        this.validateStatus ? {
                            validateStatus: this.validateStatus
                        } : undefined
                    ).then(response => this.response(response))
                        .finally(() => {
                            this.submitting = false;
                        });
                } else {
                    const inputs = this.$refs[target].inputs.filter(input => input.validationState);
                    const input = inputs.shift();

                    setTimeout(() => input.$refs.input.focus(), 700);
                }
            }
        },
        computed: {
            rules() {
                return {
                    required: value => !!value || this.$tcg('inputs.validations.required.text'),
                    //min: v => (v && v.length >= this.minCharacters) || this.$t('$pipeline.app-account-toolbar-item.tabs.create.messages.min-characters', this.minCharacters),
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || this.$tcg('inputs.validations.e-mail.invalid'); //'Invalid e-mail.'
                    },
                    password: value => validatePassword(this, value),
                    targets: (target) => !this.message?.targets.includes(target)
                }
            }
        }
    }
</script>